import Enumerable from "linq";
import React from "react";

const NSDataTableBody = ({
  rows,
  columns,
  cells,
  conditionalRowStyles,
  emptyDataComponent,
  ...props
}) => {
  return (
    <tbody>
      {(rows?.length > 0 &&
        rows?.map((row) => {
          const additionalClassNames = Enumerable.from(conditionalRowStyles)
            .where((x) => x.when(row) === true)
            .select((x) => x.classNames)
            .toJoinedString();

          return (
            <tr key={JSON.stringify(row)} className={additionalClassNames}>
              {columns?.map((column) => (
                <td
                  key={JSON.stringify(column)}
                  className={column.className}
                  data-title={column.dataTitle}>
                  {column.cell(row)}
                </td>
              ))}
            </tr>
          );
        })) || (
        <tr>
          <td colSpan={columns?.length}>{emptyDataComponent}</td>
        </tr>
      )}
    </tbody>
  );
};

export default NSDataTableBody;
