import React, { useContext, useState } from "react";
import {
  ElementStyle,
  MetaDataKeys,
  SystemSettings,
} from "../../constants/misc";
import { AppContext, UserContext } from "../../context/ContextProvider";

import { StatusCodes } from "http-status-codes";
import { Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Breadcrumbs from "../../components/Breadcrumbs";
import GoBackLink from "../../components/GoBackLink";
import PageHeaderV2 from "../../components/PageHeaderV2";
import AppForm from "../../components/forms/Form";
import FormToggleField from "../../components/forms/FormToggleField";
import SubmittButton from "../../components/forms/SubmittButton";
import StyledCard from "../../components/styled/StyledCard";
import { StorageProps } from "../../constants/storageProps";
import useRefreshProfile from "../../hooks/useRefreshProfile";
import useRefreshSystemSettings from "../../hooks/useRefreshSystemSettings";
import useImportCss from "../../hooks/userImportCss";
import appServices from "../../services/appServices";

const SettingsPage = ({ user, ...otherProps }) => {
  const { systemSettings, setSystemSettings } = useContext(AppContext);
  const userContext = useContext(UserContext);

  const location = useLocation();
  user = location.state?.user ?? userContext.currentUser;
  // console.log("user", user);

  const [isSavingSettings, setIsSavingSettings] = useState(false);
  const [isSavingAdminSettings, setIsSavingAdminSettings] = useState(false);

  useImportCss(`${process.env.PUBLIC_URL}/assets/css/ios-switch.css`);
  useRefreshProfile();
  useRefreshSystemSettings(true);

  const handleSaveAdminSettings = async (values) => {
    const systemSettingsArray = [
      { key: "maintenance_mode", value: values.maintenanceMode },
    ];

    setIsSavingAdminSettings(true);
    const response = await appServices.api.systemSettings.saveMany(
      systemSettingsArray
    );
    setIsSavingAdminSettings(false);

    if (!response.ok) return toast.error("Oops! Could not save the settings.");

    setSystemSettings(response.data);
  };

  const handleSaveSettings = async (values) => {
    const metaArray = [
      {
        key: MetaDataKeys.Settings_TwoFactorEnabled,
        value: values.twoFactorEnabled,
      },
      {
        key: MetaDataKeys.Settings_SingleSessionLimit,
        value: values.singleSessionLimit,
      },
      {
        key: MetaDataKeys.Settings_NsbiEnabled,
        value: values.nsbiEnabled,
      },
    ];

    setIsSavingSettings(true);
    const response = await appServices.api.metaData.storeUserMetaData(
      user.id,
      metaArray
    );
    setIsSavingSettings(false);

    if (!response.ok) return toast.error("Oops! Could not save the settings.");

    if (response?.status === StatusCodes.OK) {
      await appServices.api.dundas.createAccount({ user_id: user.id });

      if (user.id === userContext.currentUser.id) {
        const currentUser = {
          ...user,
          meta: response?.data,
        };
        appServices.sessionStorage.setProperty(
          StorageProps.currentUser,
          currentUser
        );
        userContext.onCurrentUserChanged(currentUser);
      }
    }
  };

  return (
    <>
      <PageHeaderV2 title={`Settings - ${user.name}`}>
        <GoBackLink />
        {/* breadcrumbs */}
        <Breadcrumbs>
          <ol className="breadcrumbs"></ol>
        </Breadcrumbs>
      </PageHeaderV2>

      <div className="row">
        <div className="col-xl-5 col-lg-6 col-md-7">
          <StyledCard title="Settings">
            <AppForm
              initialValues={{
                twoFactorEnabled: !!Number(
                  appServices.user.getMetaData(
                    user,
                    MetaDataKeys.Settings_TwoFactorEnabled
                  )
                ),
                singleSessionLimit: !!Number(
                  appServices.user.getMetaData(
                    user,
                    MetaDataKeys.Settings_SingleSessionLimit
                  )
                ),
                nsbiEnabled: !!Number(
                  appServices.user.getMetaData(
                    user,
                    MetaDataKeys.Settings_NsbiEnabled
                  )
                ),
              }}
              onSubmit={handleSaveSettings}
            >
              <FormToggleField
                containerClassName="d-flex flex-row justify-content-between"
                label="Two Factor Authentication"
                name="twoFactorEnabled"
              />
              <FormToggleField
                containerClassName="d-flex flex-row justify-content-between"
                label="Limit to Single Session"
                name="singleSessionLimit"
              />
              <FormToggleField
                containerClassName="d-flex flex-row justify-content-between"
                label="Uses NSBI"
                name="nsbiEnabled"
                visible={appServices.user.isAdmin()}
              />

              <Form.Group className="form-group text-end">
                <SubmittButton
                  title="Save Settings"
                  isLoading={isSavingSettings}
                />
              </Form.Group>
            </AppForm>
          </StyledCard>
        </div>

        {/* Admin Settings */}
        {appServices.user.isAdmin() && (
          <div className="col-xl-5 col-lg-6 col-md-7">
            <StyledCard
              title="Admin Settings"
              elementStyle={ElementStyle.Warning}
            >
              <AppForm
                initialValues={{
                  maintenanceMode:
                    systemSettings?.find(
                      (x) => x.key === SystemSettings.MaintenanceMode
                    )?.value ?? false,
                }}
                onSubmit={handleSaveAdminSettings}
              >
                <FormToggleField
                  containerClassName="d-flex flex-row justify-content-between"
                  label="NSBI Maintenance Mode"
                  name="maintenanceMode"
                  visible={appServices.user.isAdmin()}
                />

                <Form.Group className="form-group text-end">
                  <SubmittButton
                    title="Save Admin Settings"
                    isLoading={isSavingAdminSettings}
                  />
                </Form.Group>
              </AppForm>
            </StyledCard>
          </div>
        )}
      </div>
    </>
  );
};

export default SettingsPage;
