import { ElementSize, ElementStyle } from "../../../constants/misc";
import { Link, useHistory } from "react-router-dom";
import React, { useState } from "react";

import Breadcrumbs from "../../../components/Breadcrumbs";
import GoBackLink from "../../../components/GoBackLink";
import PageHeaderV2 from "../../../components/PageHeaderV2";
import { Routes } from "../../../constants/routes";
import { StatusCodes } from "http-status-codes";
import StyledAlert from "../../../components/styled/StyledAlert";
import StyledCard from "../../../components/styled/StyledCard";
import UserForm from "../../../components/forms/UserForm";
import appServices from "../../../services/appServices";
import defaults from "../../../constants/defaults";
import { toast } from "react-toastify";

const UsersCreatePage = (props) => {
  const [isBusy, setIsBusy] = useState(false);
  const [apiResponse, setApiResponse] = useState(defaults.apiResponseDefault);

  const history = useHistory();

  const handleSubmit = async (
    name,
    email,
    password,
    passwordConfirmation,
    roleId
  ) => {
    await createUserAsync(name, email, password, passwordConfirmation, roleId);
  };

  const createUserAsync = async (
    name,
    email,
    password,
    passwordConfirmation,
    roleId
  ) => {
    setIsBusy(true);
    const response = await appServices.api.users.create(
      name,
      email,
      password,
      passwordConfirmation,
      roleId
    );
    setIsBusy(false);
    // console.log(response);

    if (!response.ok) {
      setApiResponse({
        ...defaults.apiResponseDefault,
        isSuccess: false,
        errorMessage: appServices.log.extractErrorMessage(response),
      });

      if (response?.status === StatusCodes.UNPROCESSABLE_ENTITY) {
        setApiResponse({ ...apiResponse, errors: response?.data.errors });
      }

      return;
    }

    if (
      response.status === StatusCodes.OK ||
      response.status === StatusCodes.CREATED
    ) {
      setApiResponse({
        ...defaults.apiResponseDefault,
        isSuccess: true,
      });

      toast.success("User created successfully.");
      history.push(Routes.users);
    }
  };

  return (
    <>
      <PageHeaderV2 title="Create User">
        <GoBackLink />
        {/* breadcrumbs */}
        <Breadcrumbs>
          <ol className="breadcrumbs">
            <li>
              <Link to={Routes.dashboard}>Dashboard</Link>
            </li>
            <li>
              <Link to={Routes.users}>Users</Link>
            </li>
          </ol>
        </Breadcrumbs>
      </PageHeaderV2>

      <div className="row">
        <div className="col-xxl-6 col-xl-8 col-lg-10 col-md-12 mb-3">
          <StyledCard subTitle="Enter all the required fields.">
            <UserForm
              apiResponse={apiResponse}
              inputSize={ElementSize.lg}
              isBusy={isBusy}
              submitButtonText="Submit"
              withRole
              onSubmit={(name, email, password, passwordConfirmation, roleId) =>
                handleSubmit(
                  name,
                  email,
                  password,
                  passwordConfirmation,
                  roleId
                )
              }
            />
            {apiResponse.isSuccess === false && (
              <StyledAlert
                elementStyle={ElementStyle.Warning}
                style={{ padding: ".5rem", display: "inline-block" }}>
                {apiResponse.errorMessage}
              </StyledAlert>
            )}
          </StyledCard>
        </div>
      </div>
    </>
  );
};

export default UsersCreatePage;
