import React, { useContext, useEffect, useState } from "react";

import Breadcrumbs from "../../../components/Breadcrumbs";
import CustomSpinner from "../../../components/CustomSpinner";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EmptyDataBox from "../../../components/EmptyDataBox";
import GoBackLink from "../../../components/GoBackLink";
import { Link } from "react-router-dom";
import NSDataTable from "../../../components/dataTable/NSDataTable";
import PageHeaderV2 from "../../../components/PageHeaderV2";
import { Routes } from "../../../constants/routes";
import { SortDirections } from "../../../constants/misc";
import StyledCard from "../../../components/styled/StyledCard";
import StyledDropDownButton from "../../../components/styled/StyledDropDownButton";
import appServices from "../../../services/appServices";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";

const UsersDundasReportsPage = (props) => {
  const [isBusy, setIsBusy] = useState(false);
  const [data, setData] = useState([]);
  const [sortOptions, setSortOptions] = useState({
    column: "id",
    direction: SortDirections.Ascending,
  });
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    fetchUsersProjectItemsAsync(
      `?paginate=${pageSize}&page=${currentPage}&sortColumn=${sortOptions.column}&sortDirection=${sortOptions.direction}&search=${searchValue}`
    );
    return () => {};
  }, [pageSize, currentPage, searchValue, sortOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchUsersProjectItemsAsync = async (urlParams) => {
    try {
      setIsBusy(true);
      const response = await appServices.api.dundas.getUsersProjectItems(
        urlParams
      );
      if (!response.ok) return toast.error("Oops! Could not fetch the data.");

      setData(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsBusy(false);
    }
  };

  const handleSort = async (column, direction) => {
    setCurrentPage(1);
    setSortOptions({ column: column, direction: direction });
  };

  const handleSearch = async (searchText) => {
    setCurrentPage(1);
    setSearchValue(searchText);
  };

  const columns = [
    {
      header: {
        name: "id",
        text: "Id",
        sortable: true,
      },
      cell: (row) => <span>{row.id}</span>,
      dataTitle: "Id",
      className: "col-nowrap",
    },
    {
      header: {
        name: "logo",
        text: "Logo",
        sortable: true,
      },
      cell: (row) => (
        <div className="userbox">
          <figure className="profile-picture">
            <img
              className="rounded-circle"
              src={
                row?.logo?.thumbnail_url ??
                `${process.env.PUBLIC_URL}/theme/img/logged-user.png`
              }
              alt="logo"
            />
          </figure>
        </div>
      ),
      dataTitle: "Logo",
      className: "col-nowrap",
    },
    {
      header: {
        name: "name",
        text: "Name",
        sortable: true,
      },
      cell: (row) => <span>{row.name}</span>,
      dataTitle: "Name",
    },
    {
      header: {
        name: "email",
        text: "Email",
        sortable: true,
      },
      cell: (row) => <span>{row.email}</span>,
      dataTitle: "Email",
    },
    {
      header: {
        name: "items",
        text: "Dashboards/Reports",
        sortable: true,
      },
      cell: (row) => (
        <ul>
          {row.projectItems?.map((item, index) => (
            <li key={`${item.id}-${index}`}>
              <a
                href={`${process.env.REACT_APP_DUNDAS_URL}/${item.type}/${item.id}?e=true`}
                target="_blank"
                rel="noreferrer"
              >
                {item.fullName}
              </a>
            </li>
          ))}
        </ul>
      ),
      dataTitle: "Dashboards/Reports",
    },
    {
      header: {
        name: "actions",
        text: "",
      },
      dataTitle: "Menu",
      className: isMobile ? "text-start" : "text-end",
      cell: (row) => {
        const actions = [
          {
            title: "Settings",
            icon: "bx bx-cog",
            to: {
              pathname: Routes.settings,
              state: { user: row },
            },
          },
        ];

        return <StyledDropDownButton actions={actions} />;
      },
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => appServices.user.isAdmin(row),
      classNames: ["primary-light"],
    },
  ];

  return (
    <>
      <PageHeaderV2 title="Users NSBI Dashboards">
        <GoBackLink />
        {/* breadcrumbs */}
        <Breadcrumbs>
          <ol className="breadcrumbs">
            <li>
              <Link to={Routes.dashboard}>Dashboard</Link>
            </li>
            <li>
              <Link to={Routes.users}>Users</Link>
            </li>
            <li>
              <span>NSBI Dashboards</span>
            </li>
          </ol>
        </Breadcrumbs>
      </PageHeaderV2>

      <div className="row">
        <div className="col">
          <StyledCard>
            <CustomSpinner isActive={isBusy} />
            <div className="dataTables_wrapper dt-bootstrap5 no-footer">
              <NSDataTable
                className="dataTable table-no-more"
                dense
                columns={columns}
                data={data}
                // paginate
                sortBy={sortOptions.column}
                sortDirection={sortOptions.direction}
                onSort={(column, direction) => handleSort(column, direction)}
                search
                onSearch={(searchValue) => handleSearch(searchValue)}
                emptyDataComponent={<EmptyDataBox />}
                conditionalRowStyles={conditionalRowStyles}
              />
              {/* **** BOOTSTRAP PAGINATION **** */}
            </div>
          </StyledCard>
        </div>
      </div>
    </>
  );
};

export default UsersDundasReportsPage;
