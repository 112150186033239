import { Link, useHistory } from "react-router-dom";

import React from "react";
import { ElementStyle } from "../constants/misc";

const GoBackLink = ({ to, ...otherProps }) => {
  const history = useHistory();

  const handleClick = () => {
    if (!to) history.goBack();
  };

  return (
    <div className="right-wrapper text-start" style={{ float: "left" }}>
      <ol className="breadcrumbs">
        <li>
          <Link
            to={to ?? "#"}
            className={`text-${ElementStyle.Secondary}`}
            onClick={handleClick}
            style={{ display: "flex", alignItems: "center" }}
          >
            <i
              className="bx bxs-left-arrow-circle"
              style={{ marginRight: 5 }}
            ></i>
            <label
              style={{
                verticalAlign: "middle",
                cursor: "pointer",
                fontWeight: 500,
              }}
            >
              Go Back
            </label>
          </Link>
        </li>
      </ol>
    </div>
  );
};

export default GoBackLink;
