// import "../css/full-screen-content.css";

import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import GoBackLink from "../../components/GoBackLink";
import { Routes } from "../../constants/routes";
import useImportCss from "../../hooks/userImportCss";
import appServices from "../../services/appServices";

const ReportDetailsPage = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [dundasLogOnToken, setDundasLogOnToken] = useState();

  useImportCss(
    `${process.env.PUBLIC_URL}/assets/css/full-screen-content.css`,
    isSuccess
  );

  useEffect(() => {
    getDundasTokenAsync(id);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDundasTokenAsync = async () => {
    try {
      setIsLoading(true);
      const response = await appServices.api.dundas.getLogOnToken();
      // console.log("LogOn Token", response);

      if (!response.ok) return;

      setDundasLogOnToken(response.data.logOnToken);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    dundasLogOnToken && (
      <>
        <div
          style={{
            height: 30,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <GoBackLink to={Routes.reports} />
        </div>
        <iframe
          src={`${process.env.REACT_APP_DUNDAS_URL}/${location?.state?.type}/${id}?logonTokenId=${dundasLogOnToken}`}
          title={`${location?.state?.type} View`}
          className="dundas-bi-embed"
        />
      </>
    )
  );

  // return (
  //   <>
  //     {/* Make this page full scren */}
  //     <PageHeaderV2 title={location?.state?.name ?? "Details"}>
  //       <GoBackLink to={Routes.reports} />
  //       {/* breadcrumbs */}
  //       <Breadcrumbs>
  //         <ol className="breadcrumbs">
  //           <li>
  //             <Link to={Routes.dashboard}>Dashboard</Link>
  //           </li>
  //           <li>
  //             <Link to={Routes.reports}>NS BI</Link>
  //           </li>
  //         </ol>
  //       </Breadcrumbs>
  //     </PageHeaderV2>

  //     <div className="row">
  //       <div className="col">
  //         {dundasLogOnToken && (
  //           <iframe
  //             src={`${process.env.REACT_APP_DUNDAS_URL}/${location?.state?.type}/${id}?logonTokenId=${dundasLogOnToken}`}
  //             title={`${location?.state?.type} View`}
  //             className="dundas-bi-embed"
  //           />
  //         )}
  //         {/* <div
  //             // id="dundas-bi-embed"
  //             className="dundas-bi-embed"
  //             data-dundas-bi-url={process.env.REACT_APP_DUNDAS_URL}
  //             data-controller-type="Dashboard"
  //             data-file-system-id={id}
  //             data-logon-token-id={dundasLogOnToken}
  //           /> */}
  //       </div>
  //     </div>

  //     {isLoading && (
  //       <div className="row">
  //         <div className="col center">
  //           <CustomSpinner isActive={isLoading} />
  //         </div>
  //       </div>
  //     )}
  //   </>
  // );
};

export default ReportDetailsPage;
