import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, Redirect, useParams } from "react-router-dom";
import { ElementStyle, MetaDataKeys } from "../../constants/misc";
import { AppContext, UserContext } from "../../context/ContextProvider";

import Enumerable from "linq";
import { toast } from "react-toastify";
import Breadcrumbs from "../../components/Breadcrumbs";
import CustomSpinner from "../../components/CustomSpinner";
import GoBackLink from "../../components/GoBackLink";
import PageHeaderV2 from "../../components/PageHeaderV2";
import ToolBar from "../../components/ToolBar";
import UserReportsList from "../../components/UserReportsList";
import LoadingButton from "../../components/buttons/LoadingButton";
import StyledAlert from "../../components/styled/StyledAlert";
import { Routes } from "../../constants/routes";
import apiService from "../../services/api/apiServices";
import appServices from "../../services/appServices";
import MaintenancePage from "../MaintenancePage";

const ReportsPage = (props) => {
  const { folderId } = useParams();
  const { systemSettings } = useContext(AppContext);
  const userContext = useContext(UserContext);

  const [allReports, setAllReports] = useState([]);
  const [reports, setReports] = useState([]);

  const [allDundasItems, setAllDundasItems] = useState([]);
  const [dundasDashboards, setDundasDashboards] = useState([]);
  const [dundasFolders, setDundasFolders] = useState([]);
  const [hideFolders, setHideFolders] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);

  const maintenanceMode = systemSettings?.find(
    (x) => x.key === "maintenance_mode"
  )?.value;

  const dundasEnabled = !!Number(
    appServices.user.getMetaData(
      userContext.currentUser,
      MetaDataKeys.Settings_NsbiEnabled
    )
  );

  useEffect(() => {
    handleRefresh();

    return () => {
      // setReports([]);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // console.log("folderId", folderId);
    // console.log("all reports", allReports);

    if (!folderId) {
      setHideFolders(false);
      setReports([...allReports]);
      parseDundasProjectItems([...allDundasItems]);
      return;
    }

    const folderReports = allReports?.filter(
      (x) => x.folderId === parseInt(folderId)
    );
    // console.log("folder reports", folderReports);
    setHideFolders(true);
    setReports(folderReports);
    parseDundasProjectItems([...allDundasItems], folderId);
  }, [folderId, allReports]);

  const getDundasProjectItemsAsync = async () => {
    setIsBusy(true);
    const response = await apiService.dundas.getProjectItems();
    setIsBusy(false);

    if (!response.ok)
      return toast.error("Oops! Could not get your dashboards.");

    setAllDundasItems(response.data);
    parseDundasProjectItems(response.data);
  };

  const handleRefresh = async () => {
    if (dundasEnabled) await getDundasProjectItemsAsync();
    setPageLoaded(true);
  };

  const parseDundasProjectItems = (items, path) => {
    if (!items) return;

    const rootFolderName =
      path ??
      items?.[0]?.friendlyLocationElements.find((x) =>
        x.includes(userContext.currentUser?.email)
      );
    // console.log("rootFolderName", rootFolderName);

    const dashboards = items?.filter(
      (x) =>
        x.friendlyLocationElements[x.friendlyLocationElements.length - 1] ===
        rootFolderName
    );
    setDundasDashboards(dashboards);

    const folders = [];
    items?.map((item, index) => {
      const rootFolderIndex = item.friendlyLocationElements.findIndex(
        (x) => x === rootFolderName
      );

      if (rootFolderIndex >= 0) {
        const subFolder =
          item.friendlyLocationElements[rootFolderIndex + 1] ?? null;
        if (subFolder && !folders.find((x) => x.name === subFolder))
          folders.push({ name: subFolder });
      }

      return null;
    });
    setDundasFolders(folders);

    // console.log("folders", folders);
    // console.log(
    //   "itemsInThisFolder",
    //   Enumerable.from(dashboards)
    //     .select((x) => x.name)
    //     .toArray()
    // );
  };

  // console.log("reports", reports);
  // console.log("dundasDashboards", dundasDashboards);
  // console.log("dundasFolders", dundasFolders);

  if (!dundasEnabled)
    return (
      <Redirect
        to={{
          pathname: Routes.forbidden,
        }}
      />
    );

  return (
    <>
      <PageHeaderV2 title="NS BI">
        <GoBackLink />
        {/* breadcrumbs */}
        <Breadcrumbs>
          <ol className="breadcrumbs">
            <li>
              <Link to={Routes.dashboard}>Dashboard</Link>
            </li>
            <li>
              <Link to={Routes.reports}>NS BI</Link>
            </li>
            {!!folderId && (
              <li>
                <span>
                  {Enumerable.from(allReports).firstOrDefault(
                    (x) => x.folderId === parseInt(folderId)
                  )?.folderName ?? folderId}
                </span>
              </li>
            )}
          </ol>
        </Breadcrumbs>
      </PageHeaderV2>

      {(maintenanceMode && (
        <div className="row">
          <div className="col">
            <MaintenancePage title="NSBI" />
          </div>
        </div>
      )) || (
        <>
          <ToolBar>
            <li className="nav-item">
              <LoadingButton
                elementStyle={ElementStyle.Tertiary}
                isLoading={isBusy}
                onClick={handleRefresh}
              >
                Refresh
              </LoadingButton>
            </li>
          </ToolBar>

          <CustomSpinner isActive={isBusy} />

          <Row>
            {(dundasDashboards?.length === 0 &&
              dundasFolders?.length === 0 &&
              reports?.length === 0 &&
              pageLoaded && (
                <Col>
                  <StyledAlert
                    elementStyle={ElementStyle.Warning}
                    style={{ padding: ".5rem", display: "inline-block" }}
                  >
                    We were unable to find any NS BI application under your
                    account.
                    <br />
                    Please{" "}
                    <a
                      href="https://northstarsystems.ca/support/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      contact North Star Systems
                    </a>{" "}
                    for assistance.
                  </StyledAlert>
                </Col>
              )) || (
              <UserReportsList
                dundasDashboards={dundasDashboards}
                dundasFolders={dundasFolders}
                hideFolders={hideFolders}
                reports={reports}
              />
            )}
          </Row>
        </>
      )}
    </>
  );
};

export default ReportsPage;
