import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  ElementSize,
  ElementStyle,
  MetaDataKeys,
  SortDirections,
} from "../../../constants/misc";
import { ModalsContext, UserContext } from "../../../context/ContextProvider";

import { StatusCodes } from "http-status-codes";
import Enumerable from "linq";
import { Modal } from "react-bootstrap";
import context from "react-bootstrap/esm/AccordionContext";
import { isMobile } from "react-device-detect";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import Breadcrumbs from "../../../components/Breadcrumbs";
import CustomSpinner from "../../../components/CustomSpinner";
import EmptyDataBox from "../../../components/EmptyDataBox";
import GoBackLink from "../../../components/GoBackLink";
import PageHeaderV2 from "../../../components/PageHeaderV2";
import ToolBar from "../../../components/ToolBar";
import LoadingButton from "../../../components/buttons/LoadingButton";
import NSDataTable from "../../../components/dataTable/NSDataTable";
import NSDataTablePagination from "../../../components/dataTable/NSDataTablePagination";
import AddOrEditUserDialog from "../../../components/modals/AddOrEditUserDialog";
import ChangeLogoDialog from "../../../components/modals/ChangeLogoDialog";
import StyledAlert from "../../../components/styled/StyledAlert";
import StyledCard from "../../../components/styled/StyledCard";
import StyledDropDownButton from "../../../components/styled/StyledDropDownButton";
import StyledSwitch from "../../../components/styled/StyledSwitch";
import defaults from "../../../constants/defaults";
import { Routes } from "../../../constants/routes";
import useImportCss from "../../../hooks/userImportCss";
import appServices from "../../../services/appServices";

// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const UsersPage = (props) => {
  const search = useLocation().search;
  const filterBlocked = new URLSearchParams(search).get("blocked") !== null;
  const history = useHistory();
  useImportCss(`${process.env.PUBLIC_URL}/assets/css/ios-switch.css`);

  const [apiResponse, setApiResponse] = useState(defaults.apiResponseDefault);
  const [userApiResponse, setUserApiResponse] = useState(
    defaults.apiResponseDefault
  );
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState({ show: false, user: null, isBusy: false });
  const [isBusy, setIsBusy] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [deleteRemoteAccount, setDeleteRemoteAccount] = useState(false);
  const [deleteRemoteFolders, setDeleteRemoteFolders] = useState(false);

  // users table
  const [usersResponse, setUsersResponse] = useState();
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [sortOptions, setSortOptions] = useState({
    column: "id",
    direction: SortDirections.Ascending,
  });
  const [searchValue, setSearchValue] = useState("");

  const [userDialog, setUserDialog] = useState({
    show: false,
    title: "",
    user: null,
    apiResponse: userApiResponse,
    isBusy: isBusy,
    submitButtonText: "Save",
    withRole: true,
    onSubmit: () => {},
  });
  const [logoDialog, setLogoDialog] = useState({
    show: false,
    title: "Change Logo",
    user: null,
    isBusy: isBusy,
  });
  const userContext = useContext(UserContext);
  const modalsContext = useContext(ModalsContext);

  useEffect(() => {
    fetchUsersAsync(
      `?paginate=${pageSize}&page=${currentPage}&blocked=${filterBlocked}`
    );
  }, [filterBlocked]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!deleteRemoteAccount) setDeleteRemoteFolders(false);
  }, [deleteRemoteAccount]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreateUserClick = (event) => {
    setUserApiResponse(defaults.apiResponseDefault);
    setUserDialog({
      ...userDialog,
      title: "Create User",
      show: true,
      isBusy: false,
      user: null,
      onSubmit: async (data) => {
        // create the user
        await createUserAsync(data);
      },
    });
  };

  const handleEditUserClick = (event, user) => {
    event.preventDefault();

    setUserApiResponse(defaults.apiResponseDefault);
    setUserDialog({
      ...userDialog,
      title: "Edit User",
      show: true,
      isBusy: false,
      user: user,
      onSubmit: async (data) => {
        // Update the user
        await updateUserAsync(user.id, data);
      },
    });
  };

  const handleChangeLogoClick = (event, user) => {
    event.preventDefault();

    setLogoDialog({ ...logoDialog, user: user, show: true });
  };

  const handleDeleteUserClick = (event, user) => {
    try {
      event.preventDefault();
      setShowDeleteConfirmationModal({ show: true, user: user, isBusy: false });
    } catch (ex) {}
  };

  const handleRestoreUserClick = (event, user) => {
    event.preventDefault();

    const confirmation = {
      show: true,
      title: "Restore User",
      content: (
        <>
          Are you sure you want to restore <strong>{user?.name}</strong>?
        </>
      ),
      type: ElementStyle.Primary,
      isFeatured: true,
      confirmationButton: "Restore",
      cancelButton: "Cancel",
      onConfirm: async () => {
        await restoreUserAsync(user);
      },
    };
    modalsContext.setShowConfirmationModal(confirmation);
  };

  const createUserAsync = async (data) => {
    let userModal = { ...userDialog, show: true, isBusy: true };

    setUserDialog(userModal);

    setIsBusy(true);
    const response = await appServices.api.users.create(data);
    setIsBusy(false);
    // console.log(response);

    if (!response.ok) {
      setUserApiResponse({
        ...defaults.apiResponseDefault,
        isSuccess: false,
        errorMessage: appServices.log.extractErrorMessage(response),
      });

      if (response?.status === StatusCodes.UNPROCESSABLE_ENTITY) {
        setUserApiResponse({
          ...userApiResponse,
          errors: response?.data.errors,
        });
      }

      setUserDialog({ ...userModal, isBusy: false });
      return;
    }

    if (
      response.status === StatusCodes.OK ||
      response.status === StatusCodes.CREATED
    ) {
      if (
        !!Number(
          appServices.user.getMetaData(data, MetaDataKeys.Settings_NsbiEnabled)
        )
      ) {
        let dundasResponse = await appServices.api.dundas.createAccount({
          user_id: response.data?.id,
        });
        if (!dundasResponse.ok) toast.error("Could not update BI account");
        else {
          const dundasResponse =
            await appServices.api.dundas.attachProjectToAccount({
              user_id: response.data?.id,
              project_id: data.project_id,
            });
          if (!dundasResponse.ok) toast.error("Could not update BI account");
        }
      }

      setUserApiResponse({
        ...defaults.apiResponseDefault,
        isSuccess: true,
      });

      userModal = {
        ...userModal,
        apiResponse: null,
        show: false,
      };
      setUserDialog(userModal);
      toast.success("User created successfully.");
      await handleRefresh();
    }

    setUserDialog({ ...userModal, isBusy: false });
  };

  const updateUserAsync = async (id, data) => {
    let userModal = { ...userDialog, show: true, isBusy: true };

    setUserDialog(userModal);

    setIsBusy(true);
    const response = await appServices.api.users.update(id, data);
    setIsBusy(false);

    if (!response.ok) {
      setUserApiResponse({
        ...defaults.apiResponseDefault,
        isSuccess: false,
        errorMessage: appServices.log.extractErrorMessage(response),
      });

      if (response?.status === StatusCodes.UNPROCESSABLE_ENTITY) {
        setUserApiResponse({
          ...userApiResponse,
          errors: response?.data.errors,
        });
      }

      setUserDialog({ ...userModal, isBusy: false });
      return;
    }

    if (
      !!Number(
        appServices.user.getMetaData(data, MetaDataKeys.Settings_NsbiEnabled)
      )
    ) {
      let dundasResponse = await appServices.api.dundas.createAccount({
        user_id: id,
      });
      if (!dundasResponse.ok) toast.error("Could not update BI account");
      else {
        const dundasResponse =
          await appServices.api.dundas.attachProjectToAccount({
            user_id: id,
            project_id: data.project_id,
          });
        if (!dundasResponse.ok) toast.error("Could not update BI account");
      }
    }

    const newUsers = [...users];
    const index = Enumerable.from(users).indexOf((x) => x.id === id);
    newUsers[index] = response?.data;
    setUsersResponse({ ...usersResponse, data: newUsers });
    setUsers(newUsers);

    setUserApiResponse({
      ...defaults.apiResponseDefault,
      isSuccess: true,
    });

    userModal = {
      ...userModal,
      apiResponse: null,
      show: false,
    };
    setUserDialog(userModal);

    toast.success("User updated successfully.");

    setUserDialog({ ...userModal, isBusy: false });
  };

  const deleteUserAsync = async (user, force) => {
    const deleteUserModal = {
      ...showDeleteConfirmationModal,
      isBusy: true,
    };

    setShowDeleteConfirmationModal(deleteUserModal);

    // delete the remote dundas account too, if checked
    if (deleteRemoteAccount) {
      const deleteRemoteResponse = await appServices.api.dundas.deleteAccount(
        user?.id,
        deleteRemoteFolders
      );
      if (!deleteRemoteResponse.ok)
        toast.error(appServices.log.extractErrorMessage(deleteRemoteResponse));

      setDeleteRemoteAccount(false);
    }

    //-----------
    const response = await appServices.api.users.delete(user?.id, force);
    //-----------
    // console.log("delete response", response);
    setShowDeleteConfirmationModal({
      ...deleteUserModal,
      isBusy: false,
      show: false,
    });

    if (!response.ok)
      return toast.error(appServices.log.extractErrorMessage(response));

    if (
      response?.status === StatusCodes.OK ||
      response?.status === StatusCodes.NO_CONTENT
    ) {
      toast.success(response.data?.message);

      // setUsers(users.filter((x) => x.id !== user?.id));
      await handleRefresh();
    }
  };

  const restoreUserAsync = async (user) => {
    const restoreUserModal = {
      ...context.showConfirmationModal,
      isBusy: true,
    };

    modalsContext.setShowConfirmationModal(restoreUserModal);
    //-----------
    const response = await appServices.api.users.restore(user?.id);
    //-----------

    modalsContext.setShowConfirmationModal({
      ...restoreUserModal,
      isBusy: false,
      show: false,
    });

    if (!response.ok)
      return toast.error(appServices.log.extractErrorMessage(response));

    if (response?.status === StatusCodes.OK) {
      toast.success(response?.data.message);
      setUsersResponse({
        ...usersResponse,
        data: usersResponse?.data?.filter((x) => x.id !== user?.id),
      });
      // await handleRefresh();
    }
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await fetchUsersAsync(
      `?paginate=${pageSize}&page=${currentPage}&sortColumn=${sortOptions.column}&sortDirection=${sortOptions.direction}&blocked=${filterBlocked}`
    );
    setIsRefreshing(false);
  };

  const fetchUsersAsync = async (urlParams) => {
    setIsBusy(true);
    const response = await appServices.api.users.all(urlParams);
    setIsBusy(false);

    if (!response.ok)
      return setApiResponse({
        ...defaults.apiResponseDefault,
        isSuccess: false,
        errorMessage: appServices.log.extractErrorMessage(response),
      });

    setApiResponse({
      ...defaults.apiResponseDefault,
      isSuccess: true,
    });

    // remove the default first and last link
    let links = response?.data?.meta?.links.slice(1, -1);
    // strip the base_url and just pass the query params
    links = links.map((link) => {
      return {
        ...link,
        url: appServices.utility.sanitizePaginationUrl(link.url),
      };
    });

    const data = {
      ...response?.data,
      links: {
        ...response?.data?.links,
        first: appServices.utility.sanitizePaginationUrl(
          response?.data?.links?.first
        ),
        last: appServices.utility.sanitizePaginationUrl(
          response?.data?.links?.last
        ),
        prev: appServices.utility.sanitizePaginationUrl(
          response?.data?.links?.prev
        ),
        next: appServices.utility.sanitizePaginationUrl(
          response?.data?.links?.next
        ),
      },
      meta: { ...response?.data.meta, links: links },
    };
    // console.log("data", data);
    setUsersResponse(data);
    setUsers(response?.data?.data);
  };

  const columns = [
    {
      header: {
        name: "id",
        text: "Id",
        sortable: true,
      },
      cell: (row) => <span>{row.id}</span>,
      dataTitle: "Id",
      className: "col-nowrap",
    },
    {
      header: {
        name: "logo",
        text: "Logo",
        sortable: true,
      },
      cell: (row) => (
        <div className="userbox">
          <figure className="profile-picture">
            <img
              className="rounded-circle"
              src={
                row?.logo?.thumbnail_url ??
                `${process.env.PUBLIC_URL}/theme/img/logged-user.png`
              }
              alt="logo"
            />
          </figure>
        </div>
      ),
      dataTitle: "Logo",
      className: "col-nowrap",
    },
    {
      header: {
        name: "name",
        text: "Name",
        sortable: true,
      },
      cell: (row) => <span>{row.name}</span>,
      dataTitle: "Name",
    },
    {
      header: {
        name: "email",
        text: "Email",
        sortable: true,
      },
      cell: (row) => <span>{row.email}</span>,
      dataTitle: "Email",
    },
    {
      header: {
        name: "role",
        text: "Roles",
        sortable: false,
      },
      cell: (row) => (
        <span>
          {Enumerable.from(row?.roles)
            .select((role) => role.short_name)
            .toArray()
            .join(", ")}
        </span>
      ),
      dataTitle: "Roles",
    },
    {
      header: {
        name: "ts_email",
        text: "TS",
        sortable: true,
      },
      cell: (row) => <span>{row.ts?.email}</span>,
      dataTitle: "Created On",
    },
    {
      header: {
        name: "ld_username",
        text: "LD",
        sortable: true,
      },
      cell: (row) => <span>{row.ld?.email}</span>,
      dataTitle: "Created On",
    },
    {
      header: {
        name: "created_at",
        text: "Created On",
        sortable: true,
      },
      cell: (row) => <span>{new Date(row.createdAt).toDateString()}</span>,
      dataTitle: "Created On",
    },
    {
      header: {
        name: "actions",
        text: "",
      },
      dataTitle: "Menu",
      className: isMobile ? "text-start" : "text-end",
      cell: (row) => {
        const blockedUsersActions = [
          {
            title: "Restore",
            icon: "fas fa-trash-restore-alt",
            onClick: (event) => handleRestoreUserClick(event, row),
          },
        ];

        const actions = [
          {
            title: "Edit",
            icon: "fas fa-pencil-alt",
            onClick: (event) => handleEditUserClick(event, row),
          },
          {
            title: "Change Logo",
            icon: "far fa-id-card",
            onClick: (event) => handleChangeLogoClick(event, row),
          },
          {
            title: "Settings",
            icon: "bx bx-cog",
            to: {
              pathname: Routes.settings,
              state: { user: row },
            },
          },
        ];

        if (row.id !== userContext.currentUser.id) {
          actions.push({
            title: "Delete",
            icon: "fas fa-trash-alt",
            className: "text-danger",
            onClick: (event) => handleDeleteUserClick(event, row),
          });
        }

        return (
          <StyledDropDownButton
            // elementStyle={ElementStyle.Default}
            actions={(filterBlocked && blockedUsersActions) || actions}
          />
        );
      },
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => appServices.user.isAdmin(row),
      classNames: ["primary-light"],
    },
  ];

  const handlePageChange = async (page) => {
    setCurrentPage(page);

    await fetchUsersAsync(
      `?paginate=${pageSize}&page=${page}&sortColumn=${sortOptions.column}&sortDirection=${sortOptions.direction}&search=${searchValue}&blocked=${filterBlocked}`
    );
  };

  const handleRowsPerPageChange = async (newPerPage, page) => {
    setCurrentPage(1);
    setPageSize(newPerPage);

    await fetchUsersAsync(
      `?paginate=${newPerPage}&page=${1}&sortColumn=${
        sortOptions.column
      }&sortDirection=${
        sortOptions.direction
      }&search=${searchValue}&blocked=${filterBlocked}`
    );
  };

  const handleSort = async (column, direction) => {
    setCurrentPage(1);
    setSortOptions({ column: column, direction: direction });

    await fetchUsersAsync(
      `?paginate=${pageSize}&page=${1}&sortColumn=${column}&sortDirection=${direction}&search=${searchValue}&blocked=${filterBlocked}`
    );
  };

  const handleSearch = async (searchText) => {
    setCurrentPage(1);
    setSearchValue(searchText);

    await fetchUsersAsync(
      `?paginate=${pageSize}&page=${1}&sortColumn=${
        sortOptions.column
      }&sortDirection=${
        sortOptions.direction
      }&search=${searchText}&blocked=${filterBlocked}`
    );
  };

  const handleLogoUpdate = (userId, logo) => {
    const newUsers = [...users];
    const index = Enumerable.from(users).indexOf((x) => x.id === userId);
    newUsers[index].logo = logo;
    setUsers(newUsers);
  };

  const PaginationComponent = () => (
    <NSDataTablePagination
      page={currentPage}
      total={usersResponse?.meta?.total}
      lastPage={usersResponse?.meta?.last_page}
      pageSize={pageSize}
      from={usersResponse?.meta?.from}
      to={usersResponse?.meta?.to}
      links={usersResponse?.meta?.links}
      firstLink={usersResponse?.links?.first}
      prevLink={usersResponse?.links?.prev}
      nextLink={usersResponse?.links?.next}
      lastLink={usersResponse?.links?.last}
      minimal
      onPageChange={(page) => handlePageChange(page)}
      onPageSizeChange={(newPerPage) =>
        handleRowsPerPageChange(newPerPage, currentPage)
      }
    />
  );

  return (
    <>
      <PageHeaderV2 title={filterBlocked ? "Blocked Users" : "Users"}>
        <GoBackLink />
        {/* breadcrumbs */}
        <Breadcrumbs>
          <ol className="breadcrumbs">
            <li>
              <Link to={Routes.dashboard}>Dashboard</Link>
            </li>
            <li>
              <span>Users</span>
            </li>
          </ol>
        </Breadcrumbs>
      </PageHeaderV2>

      <ToolBar>
        <li className="nav-item">
          <LoadingButton
            elementStyle={ElementStyle.Secondary}
            isDisabled={isBusy}
            onClick={() => history.push(Routes.usersDundasReports)}
          >
            NSBI Dashboards
          </LoadingButton>
        </li>
        <li className="nav-item">
          <LoadingButton
            elementStyle={ElementStyle.Tertiary}
            isLoading={isRefreshing}
            isDisabled={isBusy}
            onClick={handleRefresh}
          >
            Refresh
          </LoadingButton>
        </li>
      </ToolBar>

      <div className="row">
        <div className="col">
          <StyledCard>
            {apiResponse.isSuccess === false && (
              <StyledAlert
                elementStyle={ElementStyle.Warning}
                style={{ padding: ".5rem", display: "inline-block" }}
              >
                {apiResponse.errorMessage}
              </StyledAlert>
            )}
            {apiResponse.isSuccess !== false && (
              <div className="row">
                <div className="col">
                  {!filterBlocked && (
                    <LoadingButton
                      className="mb-3"
                      isDisabled={isBusy}
                      onClick={handleCreateUserClick}
                    >
                      Add New
                    </LoadingButton>
                  )}
                  <CustomSpinner isActive={isBusy} />
                  <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                    <PaginationComponent />
                    <NSDataTable
                      className="dataTable table-no-more"
                      dense
                      columns={columns}
                      data={users}
                      paginate
                      sortBy={sortOptions.column}
                      sortDirection={sortOptions.direction}
                      onSort={(column, direction) =>
                        handleSort(column, direction)
                      }
                      search
                      onSearch={(searchValue) => handleSearch(searchValue)}
                      emptyDataComponent={<EmptyDataBox />}
                      conditionalRowStyles={conditionalRowStyles}
                    />
                    {/* **** BOOTSTRAP PAGINATION **** */}
                    <PaginationComponent />
                  </div>
                </div>
              </div>
            )}
          </StyledCard>
        </div>
      </div>

      {ReactDOM.createPortal(
        <AddOrEditUserDialog
          title={userDialog?.title}
          user={userDialog?.user}
          isBusy={userDialog?.isBusy}
          submitButtonText={userDialog?.submitButtonText}
          inputSize={ElementSize.md}
          withRole={userDialog?.withRole}
          onSubmit={userDialog?.onSubmit}
          apiResponse={userApiResponse}
          show={userDialog?.show}
          onHide={() => {
            setUserDialog({ ...userDialog, apiResponse: null, show: false });
          }}
        />,
        document.getElementById("overlay-root")
      )}

      {ReactDOM.createPortal(
        // Delete user modal confirmation
        <Modal
          dialogClassName="link-app-modal"
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showDeleteConfirmationModal.show}
        >
          <StyledCard
            title="Confirmation"
            elementStyle={ElementStyle.Danger}
            isFeatured
          >
            <div className="row">
              <div className="col">
                <p>
                  Are you sure you want to delete{" "}
                  <strong>{showDeleteConfirmationModal.user?.name}</strong>?
                </p>
                <p>
                  <small>
                    <strong>Delete</strong>: The user's email will become
                    available and can be registered again.
                    <br />
                    <strong>Delete &amp; Block</strong>: The user will be locked
                    out and won't be able to register again.
                  </small>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col col-lg-12 d-flex flex-column">
                <div>
                  <StyledSwitch
                    elementStyle={ElementStyle.Primary}
                    isChecked={deleteRemoteAccount}
                    label="Also delete from NSBI (if exists)"
                    name="deleteRemoteAccount"
                    onChange={(e) => setDeleteRemoteAccount(e.target.checked)}
                  />
                </div>
                {!!deleteRemoteAccount && (
                  <div>
                    <StyledSwitch
                      className="ms-5"
                      elementStyle={ElementStyle.Primary}
                      isChecked={deleteRemoteFolders}
                      label="Delete Folders (if exist)"
                      name="deleteRemoteFolders"
                      onChange={(e) => setDeleteRemoteFolders(e.target.checked)}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col text-end">
                <button
                  className="btn btn-default modal-dismiss mt-2 me-2"
                  onClick={() =>
                    setShowDeleteConfirmationModal({
                      ...showDeleteConfirmationModal,
                      show: false,
                    })
                  }
                >
                  Cancel
                </button>
                <LoadingButton
                  className="modal-dismiss mt-2 me-2"
                  elementStyle={ElementStyle.Danger}
                  isDisabled={showDeleteConfirmationModal.isBusy}
                  onClick={async () =>
                    await deleteUserAsync(
                      showDeleteConfirmationModal.user,
                      true
                    )
                  }
                >
                  Delete
                </LoadingButton>
                <LoadingButton
                  className="modal-dismiss mt-2"
                  elementStyle={ElementStyle.Danger}
                  isDisabled={showDeleteConfirmationModal.isBusy}
                  onClick={async () =>
                    await deleteUserAsync(
                      showDeleteConfirmationModal.user,
                      false
                    )
                  }
                >
                  Delete &amp; Block
                </LoadingButton>
              </div>
            </div>
          </StyledCard>
        </Modal>,
        document.getElementById("overlay-root")
      )}

      {ReactDOM.createPortal(
        <ChangeLogoDialog
          user={logoDialog.user}
          show={logoDialog.show}
          onHide={() => {
            setLogoDialog({ ...logoDialog, show: false });
          }}
          onSuccess={(logo) => {
            handleLogoUpdate(logoDialog.user?.id, logo);
          }}
        />,
        document.getElementById("overlay-root")
      )}
    </>
  );
};

export default UsersPage;
