import { ElementSize, ElementStyle } from "../../../constants/misc";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Breadcrumbs from "../../../components/Breadcrumbs";
import GoBackLink from "../../../components/GoBackLink";
import PageHeaderV2 from "../../../components/PageHeaderV2";
import { Routes } from "../../../constants/routes";
import { StatusCodes } from "http-status-codes";
import StyledAlert from "../../../components/styled/StyledAlert";
import StyledCard from "../../../components/styled/StyledCard";
import UserForm from "../../../components/forms/UserForm";
import appServices from "../../../services/appServices";
import defaults from "../../../constants/defaults";
import { toast } from "react-toastify";

const UsersEditPage = (props) => {
  const [isBusy, setIsBusy] = useState(false);
  const [apiResponse, setApiResponse] = useState(defaults.apiResponseDefault);

  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();

  const [user, setUser] = useState(location?.state?.user ?? null);
  // const user = location?.state?.user;

  useEffect(() => {
    if (user === null) {
      fetchUserAsync(id);
    }
    return () => {};
  }, [id, user]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchUserAsync = async (id) => {
    const response = await appServices.api.users.get(id);

    if (!response.ok) {
      toast.error(appServices.log.extractErrorMessage(response));
      if (response.status === StatusCodes.NOT_FOUND) {
        history.push(Routes.notFound);
      }
      return;
    }

    setUser(response?.data);
  };

  const handleSubmit = async (data) => {
    await updateUserAsync(data);
  };

  const updateUserAsync = async (data) => {
    setIsBusy(true);
    const response = await appServices.api.users.update(user.id, data);
    setIsBusy(false);
    // console.log(response);

    if (!response.ok) {
      setApiResponse({
        ...defaults.apiResponseDefault,
        isSuccess: false,
        errorMessage: appServices.log.extractErrorMessage(response),
      });

      if (response?.status === StatusCodes.UNPROCESSABLE_ENTITY) {
        setApiResponse({ ...apiResponse, errors: response?.data.errors });
      }

      return;
    }

    if (
      response.status === StatusCodes.OK ||
      response.status === StatusCodes.CREATED
    ) {
      setApiResponse({
        ...defaults.apiResponseDefault,
        isSuccess: true,
      });

      const dundasResponse =
        await appServices.api.dundas.attachProjectToAccount({
          user_id: user.id,
          project_id: data.project_id,
        });
      if (!dundasResponse.ok) toast.error("Could not update BI account");

      toast.success("User updated successfully.");
      history.push(Routes.users);
    }
  };

  return (
    <>
      <PageHeaderV2 title="Edit User">
        <GoBackLink />
        {/* breadcrumbs */}
        <Breadcrumbs>
          <ol className="breadcrumbs">
            <li>
              <Link to={Routes.dashboard}>Dashboard</Link>
            </li>
            <li>
              <Link to={Routes.users}>Users</Link>
            </li>
          </ol>
        </Breadcrumbs>
      </PageHeaderV2>

      <div className="row">
        <div className="col-xxl-6 col-xl-8 col-lg-10 col-md-12 mb-3">
          <StyledCard subTitle="Enter all the required fields.">
            <UserForm
              user={user}
              apiResponse={apiResponse}
              inputSize={ElementSize.lg}
              isBusy={isBusy}
              submitButtonText="Submit"
              withRole
              onSubmit={handleSubmit}
            />
            {apiResponse.isSuccess === false && (
              <StyledAlert
                elementStyle={ElementStyle.Warning}
                style={{ padding: ".5rem", display: "inline-block" }}>
                {apiResponse.errorMessage}
              </StyledAlert>
            )}
          </StyledCard>
        </div>
      </div>
    </>
  );
};

export default UsersEditPage;
